import { createApp } from 'vue'
import App from './App.vue'

import { createRouter, createWebHistory } from 'vue-router'

import Cookies from 'js-cookie';

import Home from '@/views/Home.vue'
import Profile from '@/views/Profile.vue'
import Guide from '@/views/Guide.vue'
import Docs from '@/views/Docs.vue'

const routes = [
  {
    path: '/allow',
    name: 'allow', 
    component: () => import('@/views/Allow.vue') 
  },
  {
    path: '/allownot',
    name: 'allownot', 
    component: () => import('@/views/AllowNot.vue') 
  },

  { path: '/', name: 'welcome', component: () => import('@/views/Welcome.vue') },
  // { path: '/home', name: 'home', component: () => import('@/views/Home.vue') },

  {
    path: '/docs',
    name: 'docs',
    component: Docs,
    children: [
        { path: 'privacy', component: () => import('@/views/Docs/Privacy.vue') },
    ]
  },

  {
    path: '/guide',
    name: 'guide',
    component: Guide,
    children: [
        { path: '', component: () => import('@/views/Guide/About.vue') },
        { path: 'agents', component: () => import('@/views/Guide/Agents.vue') },
        { path: 'keys', component: () => import('@/views/Guide/Keys.vue') },
        { path: 'chat', component: () => import('@/views/Guide/Chat.vue') },
        { path: 'token', component: () => import('@/views/Guide/Token.vue') },
        { path: 'roadmap', component: () => import('@/views/Guide/Roadmap.vue') },
        { path: 'invite', component: () => import('@/views/Guide/Invite.vue') },
        { path: 'Create', component: () => import('@/views/Guide/Create.vue') },
        { path: 'web3', component: () => import('@/views/Guide/Web3.vue') },
    ]
  },

  {
    path: '/user',
    name: 'user',
    component: Profile
  },

  {
    path: '/:catchAll(.*)*',
    name: "Error",
    component: Error,
  },
  
];

// const menuItems = [
// ];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Limit access by cookie
router.beforeEach((to, from, next) => {
  const myCookie = Cookies.get('ALLOW');
  if (to.path !== '/allownot' && to.path !== '/allow' && to.path !== '/' && to.path !== '/docs/terms' && to.path !== '/docs/privacy' && myCookie!="YES") {
    next('/allownot');
  } else {
    next();
  } 
})

createApp(App).use(router).mount('#app')

// const app = createApp(App)
// app.mixin(isActiveTabMixin);
// app.config.globalProperties.$menuItems = menuItems;
// app.use(router).mount('#app')