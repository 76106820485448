<template>
    
    <div class="w-full h-16 
    border-t border-zinc-800 h-16 w-full
    backdrop-blur bg-zinc-900 bg-opacity-40 z-50
    text-center ">

        <div class="text-zinc-400 text-sm flex flex-row justify-between p-5">
            <div class="uppercase text-zinc-500 text-sm">© 2024 Quota</div>
            <router-link to="/docs/privacy">Privacy Policy</router-link>
        </div>


    </div>

</template>