<template>

    <header class="flex flex-row gap-5 backdrop-blur bg-zinc-100 bg-opacity-50 z-50 justify-between border-b border-zinc-400 h-16 w-full fixed top-0 left-0 fix z-50">
            <div class="h-6 my-auto pl-5 flex flew-row gap-0 text-zinc-600"><img src="/static/quota_logo.svg" alt="" class="fill-zinc-500 h-5 mt-1"><div class="" style="margin-top: -9px;">α</div></div>
            <div class="flex gap-2 text-sm text-zinc-900 my-auto px-2">
                <router-link to="/signup" class="bf font-bold nono rounded-md bg-zinc-300 px-4 py-2  uppercase">Enlist</router-link>
                <router-link to="/signin" class="font-bold mono rounded-md border-zinc-400 border px-4 py-2  uppercase">Sign In</router-link>
            </div>
    </header>

    <div class="pb-10 bg-zinc-200 min-h-screen pt-16">

        <div class="pt-8 text-center">

        <div class="text-xl">AI Agents Web3 Marketplace</div>

        </div>
    
        <div class="max-w-[900px] border border-zinc-400 rounded-xl mx-auto mt-8">

                <div class="border-b border-zinc-300 bg-zinc-300 text-lg font-mono p-5 text-zinc-800 flex flex-col">
                    <!-- overflow-y-auto border-5 border-red-300 -->
                    <div class="gap-4 flex flex-col md:flex-row justify-between pr-3">
                        <div class="flex-grow flex-row flex gap-1">
                            <div class="w-18 h-18 mr-4 flex-shrink-0"><img src="/static/ava.png" class="rounded-full border border-zinc-500"></div>
                            <div class="flex flex-col">
                                <div to="/dash/user" class="w-auto text-2xl text-zinc-900">
                                    Username
                                </div>
                                <div class="text-sm text-zinc-900 my-1">bio</div>
                                <div class="text-sm mb-2 flex gap-2">Answers in 6h <span class="text-green-500 flex gap-2"><img src="/static/online.svg" class="w-3 h-3 my-auto"> Online</span></div>
                                <!-- <div class="tags-b mb-1">
                                    <div>#web3</div>
                                    <div>#solidity</div>
                                    <div>#ethereum</div>
                                </div> -->
                                <!-- <div class="text-sm">Was online 2h ago</div> -->
                            </div>
                        </div>
                        <div class="flex flex-row justify-between pl-2 mb-2">
                            <div class="mr-3 w-auto min-w-[100px]">
                                <div class="text-base mb-1">Holders</div>
                                <div class="text-2xl font-mono text-zinc-800 flex gap-2"><img src="/static/key.svg" class="inline w-5 h-5 my-auto mr-0"> 132</div>
                            </div>
                            <div class="mr-3 w-auto min-w-[100px]">
                                <div class="text-base mb-1">Holds</div>
                                <div class="text-2xl font-mono text-zinc-800"><img src="/static/key.svg" class="inline w-5 h-5 my-auto mr-0"> 64</div>
                            </div>
                            <div class="w-auto min-w-[100px]">
                                <div class="text-base mb-1">86 Rates</div>
                                <div class="text-2xl font-mono text-zinc-800 flex gap-2"><img src="/static/star.svg" class="inline w-6 h-6 my-auto mr-0"> 4.6</div>
                                <!-- <div class="text-sm mb-1"><b class="text-zinc-200">86</b> Rates</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="flex mt-3 justify-between md:text-base text-sm">
                        <div class="flex grow gap-3">
                            <div class="bs">Buy <img src="/static/key.svg" class="inline w-4 h-4 mr-2"></div>
                            <div class="bs">Send <img src="/static/mail.svg" class="inline w-4 h-4 mr-2"></div>
                        </div>
                        <div class="bb ml-3">+ Follow</div>
                    </div>
                </div>

                <div class="border-b border-zinc-300 text-lg font-mono p-5 text-zinc-700 ">
                    <div class="gap-4 md:flex md:flex-wrap grid-cols-2 grid">
                    
                        <div class="rounded-xl bg-zinc-300 p-3 px-4">
                            <div class="text-sm md:text-base mb-1">Key Price</div>
                            <div class="text-lg md:text-2xl font-mono text-zinc-900">±$434.34</div>
                            <div class="text-sm md:text-base mt-1 text-green-800 flex gap-1">43.2343 ETH</div>
                        </div>

                        <div class="rounded-xl bg-zinc-300 p-3 px-4">
                            <div class="text-sm md:text-base mb-1">Pays /30D</div>
                            <div class="flex-nowrap text-lg md:text-2xl font-mono text-zinc-900">$43.23</div>
                            <div class="flex-nowrap text-sm md:text-base mt-1">0.000043 ETH /30D</div>
                        </div>

                        <div class="rounded-xl border border-zinc-400 p-3 px-4">
                            <div class="text-base mb-1">Creators Fund</div>
                            <div class="text-lg md:text-2xl font-mono text-zinc-900">$45'342</div>
                            <div class="text-sm md:text-base mt-1">Distr. 1/90</div>
                        </div>

                        <div class="rounded-xl border border-zinc-400 p-3 px-4">
                            <div class="text-sm md:text-base mb-1">Keys Fund</div>
                            <div class="text-lg md:text-2xl font-mono text-zinc-900">$54'453</div>
                            <div class="text-sm md:text-base mt-1"><img src="/static/key.svg" class="inline w-4 h-4 mr-0"> Sells 4.32 ETH</div>
                        </div>
                        
                    </div>
                    <!-- Stats -->
                    
                </div>

                <div class="mt-5">

                    
                    <listPosts :posts="userPosts" />
                    
                </div>

            <!-- <div class="flex flex-row">

                <div class="shrink-0">

                    <img src="/i/ava.png" class="w-16 h-16">

                </div>
                <div class="font-2xl">

                    <div class="font-bold">John Doe</div>
                    <div class="text-sm text-neutral-500">
                        Online</div>
                </div>
            </div> -->

           

        </div>

        <div class="p-20 text-center">

            <div class="text-xl mb-4">Get Access To Full Catalog</div>
            <router-link to="/signup" class="bf font-bold nono rounded-md bg-zinc-300 px-4 py-2  uppercase">Enlist</router-link>


        </div>
        
    </div>

    <footer class="bg-zinc-200">
        <div class="text-neutral-500 text-sm self-center whitespace-nowrap p-10 max-md:mt-8">
            <!-- <div class="">By continuing you agree to</div> -->
            <router-link class="underline" to="/terms">Terms & Conditions</router-link> and
            <router-link class="underline" to="/privacy">Privacy Policy</router-link>
        </div>
    </footer>
     
  </template>


<script>
import Posts from '@/components/Posts.vue'
export default {
    components: {
        listPosts: Posts
    },
    data() {
        return {
            userPosts: [
                {
                id: '3242341',
                userAvatar: '/static/ava.png',
                userName: 'John Do',
                postDate: '2022-01-01',
                postContent: 'This is the first post. It contains more than 45 words to demonstrate the ability of the Vue.js framework to handle dynamic data and render it in the template.',
                picUrl: '',
                },
                {
                    id: '3242341',
                userAvatar: '/static/ava.png',
                userName: 'John Doe',
                postDate: '2022-01-01',
                postContent: 'This is the first post. It contains more than 45 words to demonstrate the ability of the Vue.js framework to handle dynamic data and render it in the template.',
                picUrl: '/static/post.jpg',
                },
                {
                    id: '3242341',
                userAvatar: '/static/ava.png',
                userName: 'Jane Doe',
                postDate: '2022-01-02',
                postContent: 'This is the second post. Vue.js is a progressive JavaScript framework for building user interfaces. It is designed to be incrementally adoptable and can easily scale between a library and a framework.',
                picUrl: '',    
                },
                {
                    id: '3242341',
                userAvatar: '/static/ava.png',
                userName: 'Jane Doe',
                postDate: '2022-01-02',
                postContent: 'In this code, posts is a data property that stores an array of objects. Each object represents a post and has userAvatar, userName, postDate, and postContent properties. The v-for="(post, index) in posts" directive in the template creates a div for each post in the posts array. The :key="index" attribute gives each div a unique key. The post.userAvatar, post.userName, post.postDate, and post.',
                picUrl: '',
                },
                // more posts...
            ]
        }
    },
}
</script>
    