<template>

    <div class="flex flex-row gap-5 justify-between
    border-b border-zinc-800 h-16 w-full fixed top-0 left-0 fix
    backdrop-blur bg-zinc-900 bg-opacity-40 z-50 
    ">
            <div class="h-6 my-auto pl-5 text-zinc-400">
                <router-link to="/" class=" flex flew-row gap-0"><img src="/i/quota.svg" alt="" class="fill-zinc-500 h-5 mt-1"><div class="" style="margin-top: -9px;">α</div></router-link>
            </div>
            <div class="flex gap-2 text-sm text-zinc-300 my-auto px-2">
                <router-link to="/signup" class="font-bold nono rounded-md bg-[#34334E] px-4 py-2 hover:text-white uppercase">Enlist</router-link>
                <router-link to="/signin" class="font-bold mono rounded-md border-[#34334E] border px-4 py-2 hover:text-white uppercase">Sign In</router-link>
            </div>
    </div> 

    <div class="flex md:flex-row flex-col mt-16 border-b border-zinc-800 text-left text-lg" ref="scrollDiv">

        <div class="w-full md:w-1/5 md:sticky top-16 border-r border-zinc-800">
            <div class=" flex flex-col">
                <div class="uppercase text-zinc-400 px-6 py-4 text-sm">Guidelines</div>

                <router-link to="/guide/" class="border-t border-zinc-800 px-6 py-4  text-white hover:bg-zinc-700">
                    About QUOTA
                </router-link>
                <router-link to="/guide/agents" class="border-t border-zinc-800 px-6 py-4  text-white hover:bg-zinc-700">
                    AI Agents
                </router-link>
                <router-link to="/guide/keys" class="border-t border-zinc-800 px-6 py-4  text-white hover:bg-zinc-700">
                    Agents' Keys
                </router-link>
                <router-link to="/guide/chat" class="border-t border-zinc-800 px-6 py-4  text-white hover:bg-zinc-700">
                    Using Agents
                </router-link>
                <router-link to="/guide/token" class="border-t border-zinc-800 px-6 py-4  text-white hover:bg-zinc-700">
                    $QUOTA Token
                </router-link>
                <router-link to="/guide/roadmap" class="border-t border-zinc-800 px-6 py-4  text-white hover:bg-zinc-700">
                    Roadmap
                </router-link>
                <router-link to="/guide/invite" class="border-t border-zinc-800 px-6 py-4  text-white hover:bg-zinc-700">
                    Invitations
                </router-link>
            </div>

        </div>
        <div class="w-full md:w-4/5 md:p-10 text-zinc-100">

            <router-view/>                

        </div>
    </div>

    <div class="p-10 justify-center">


        <div class="text-zinc-100 mt-10">
            <div class="text-2xl text-shadow-lg mb-2">Get Access to QUOTA</div>
            <!-- Explore <B>4391+</B> AI Agents -->
            <router-link to="/signup" class="but-1 but-a mt-4 shadow-lg mono uppercase">Enlist Today</router-link>
        </div>


    </div>

    <Footer/>


</template>

<script>
import Footer from '@/components/Footer.vue'

export default {

    components: {
        Footer
    },
    
    mounted() {
        window.scrollTo(0, 0);
        // console.log('updated');
        // this.$nextTick(() => {
        //     this.$refs.scrollDiv.scrollTop = 0;
        // });
    },
    updated() {
        window.scrollTo(0, 0);
        // console.log('updated');
        // this.$nextTick(() => {
        //     this.$refs.scrollDiv.scrollTop = 0;
        // });
    },
}
</script>


<style>

h2 { margin-bottom:20px; color:#A49496; }
p { margin-bottom:20px; }

</style>