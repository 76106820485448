<template>

    <div class=" mt-20 border-t border-zinc-800 text-center p-20">

        <div class="inline-block ">
            <router-link to="/"><img src="/i/quota.svg" alt="" class="fill-zinc-500 h-5 mt-1"></router-link>
        </div>

        <div class="md:w-[60%] max-w-[400px] mx-auto">

            <!-- <div class="md:flex md:flex-row grid grid-cols-3 md:gap-8 gap-6 mt-12"> -->
            <div class="gap-6 mt-12 grid grid-cols-3">

                <router-link to="/" class="border-zinc-700 mx-auto border rounded-full w-20 h-20 p-6 social-hover"><img src="/static/social/x.svg"></router-link>
                <!-- <router-link to="/" class="border-zinc-700 border mx-auto rounded-full w-16 h-16 p-5 b-hover"><img src="/static/social/ds.svg"></router-link> -->
                <router-link to="/" class="border-zinc-700 mx-auto  border rounded-full w-20 h-20 p-5 social-hover"><img src="/static/social/tg.svg" ></router-link>
                <router-link to="/" class="border-zinc-700 mx-auto  border rounded-full w-20 h-20 p-5 social-hover"><img src="/static/social/yt.svg" ></router-link>
                <!-- <router-link to="/" class="border-zinc-700 border mx-auto rounded-full w-16 h-16 p-5 b-hover"><img src="/static/social/in.svg"></router-link> -->
                <!-- <router-link to="/" class="border-zinc-700 border mx-auto rounded-full w-16 h-16 p-5 b-hover"><img src="/static/social/fb.svg"></router-link> -->
                <!-- <router-link to="/" class="mx-auto rounded-full w-16 h-16 p-4"><img src="/static/social/medium.svg"></router-link> -->

            </div>
        </div>

        <div class="text-zinc-400 text-sm mt-12 mb-16">
            <router-link to="/docs/terms">Terms & Conditions</router-link>
            <div class="inline-block mx-4">&#183;</div>
            <router-link to="/docs/privacy">Privacy Policy</router-link>
        </div>
        
        <!-- 
        <div class="uppercase text-zinc-600 text-sm mt-10 mb-10">
            DISCLAIMER
        </div>
        -->

        <div class="uppercase text-zinc-500 text-sm mt-4">© 2024 Quota</div>

    </div>

</template>