<template>
    
    <div class="post max-w-[640px] mx-auto" v-for="(post, index) in posts" :key="index">
        <div class="rounded-xl bg-zinc-200 my-5 border border-zinc-300 p-4 md:p-6">
            <div class="flex items-center mb-4 md:mb-5">
            <img :src="post.userAvatar" alt="User avatar" class="w-12 h-12 rounded-full mr-4">
            <div>
                <div class="font-bold">{{ post.userName }}</div>
                <div class="text-sm text-gray-500">{{ post.postDate }}</div>
            </div>
            </div>
            <img v-if="post.picUrl" :src="post.picUrl" class="w-full mx-auto h-42 rounded-xl md:mb-5 mb-3">
            <div class="text-lg md:mb-4 mb-2">
                <div class="shrinked-post">
                    {{ post.postContent }}
                </div>
                <div class="mr-2 brand flex flex-row gap-2">
                    <img src="/static/ico/lock-fill-b.svg" class="inline w-5 h-5 my-auto"> 
                    <div class="my-auto">Key Gated Content</div>
                </div>
            </div>
            <div class="tags-b md:mb-4 mb-2 mt-1">
                <div>#web3</div>
                <div>#solidity</div>
                <div>#ethereum</div>
            </div>
            <div class="flex mt-2">

                <div class="bs"><img src="/static/key.svg" class="inline w-4 h-4 mr-2"> View More</div>
                <div class="bb mr-2 ml-auto"><img src="/static/bm.svg" class="inline w-4 h-4"></div>
            </div>  
            
        </div>
    </div>

</template>

<script>
export default {
  props: ['posts'],
}
</script>